<template>
  <section
      id="dashboard-analytics"
      data-app
  >
    <div>
      <b-button
          v-ripple.400="'rgba(186, 191, 199, 0.15)'"
          variant="outline-primary"
          @click="collapseExpandChart()"
      >
        {{ this.collapseChartButtonName }}
      </b-button>
    </div>
    <br/>
    <b-row
        v-if="activeProjectId != null"
        class="match-height"
    >
      <transition
          mode="out-in"
          name="fade"
      >
        <b-col
            v-show="emptyGraph !== 'NO_STATS' && !collapseChart"
            cols="12"
        >
          <b-card no-body>
            <b-card-header>
              <div>
                <b-card-title class="mb-1">
                  Статистика
                </b-card-title>
                <b-card-sub-title>Сводная таблица</b-card-sub-title>
              </div>
              <!-- Выбор даты -->
              <div class="d-flex align-items-center">
                <b-button-group
                    size="sm"
                    style="margin-right: 25px"
                >
                  <b-button
                      v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                      variant="outline-primary"
                      @click="changeGroupByGraph(0)"
                  >
                    По дням
                  </b-button>
                  <b-button
                      v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                      variant="outline-primary"
                      @click="changeGroupByGraph(1)"
                  >
                    По месяцам
                  </b-button>
                  <b-button
                      v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                      variant="outline-primary"
                      @click="changeGroupByGraph(2)"
                  >
                    По годам
                  </b-button>
                </b-button-group>
                <feather-icon
                    icon="CalendarIcon"
                    size="16"
                />
                <flat-pickr
                    v-model="rangeData"
                    :config="dataPickerConfig"
                    class="form-control flat-picker bg-transparent border-0 shadow-none"
                    placeholder="YYYY-MM-DD"
                    @on-close="changeDatesRange"
                />
              </div>
              <!-- Выбор даты -->
            </b-card-header>
            <b-overlay
                :show="showChartLoading"
                blur="0"
                opacity=".75"
                rounded="sm"
                spinner-variant="primary"
                variant="white"
            >
              <b-card-body>
                <vue-apex-charts
                    ref="chart"
                    :options="changedChartOptions"
                    :series="changedSeries"
                    height="400"
                />
              </b-card-body>
            </b-overlay>
          </b-card>
        </b-col>
      </transition>

      <transition
          mode="out-in"
          name="fade"
      >
        <b-col
            v-show="emptyStats !== 'NO_STATS'"
            cols="12"
        >
          <div>
            <b-col
                cols="10"
                style="padding-left:0"
            >
              <b-form-row>
                <b-col
                    class="mb-1"
                    cols="6"
                    lg="3"
                    md="4"
                >
                  <v-select
                      v-model="perPage"
                      :options="optionsPerPages"
                      :searchable="false"
                      label="text"
                  >
                    <template v-slot:no-options>
                      Ничего не найдено
                    </template>
                  </v-select>
                </b-col>
                <b-col
                    cols="6"
                    lg="3"
                    md="4"
                >
                  <v-select
                      v-model="chosenCampaigns"
                      :clearable="false"
                      :close-on-select="false"
                      :deselect-from-dropdown="true"
                      :options="campaignOptions"
                      :selected-option-text="campaignFormatLabels"
                      label="text"
                      multiple
                      placeholder="Выберите название кампаний"
                  >
                    <template v-slot:no-options>
                      Ничего не найдено
                    </template>
                  </v-select>
                </b-col>
              </b-form-row>
            </b-col>
            <b-link
                v-b-modal.modal-xl
                style="text-decoration: dotted underline;
              text-underline-offset: 3px;"
            >
              Показать доступные столбцы
            </b-link>
            <b-modal
                id="modal-xl"
                centered
                ok-only
                ok-title="Закрыть"
                size="xl"
                title="Выбор столбцов"
            >
              <section>
                <b-col cols="12">
                  <b-field
                      class="mb-2 mt-1"
                  >
                    <b-row>
                      <b-col
                          v-for="(type, type_index) in groupedColumns"
                          :key="type_index"
                      >
                        <h4
                            class="mb-1"
                        >
                          {{ type_index }}
                          <span v-if="!isDisabledColumns(type_index)">
                            <b-badge
                                style="font-size: 60%;"
                                variant="danger"
                            >
                              не подключено
                            </b-badge>
                          </span>
                        </h4>
                        <div
                            v-for="(column, index) in type"
                            :key="index"
                            class="control mb-1"
                        >
                          <b-checkbox
                              v-model="column.display"
                              :disabled="!isDisabledColumns(type_index)"
                          >
                            {{ column.title }}
                          </b-checkbox>
                        </div>
                      </b-col>
                    </b-row>
                  </b-field>
                </b-col>
              </section>
            </b-modal>

            <b-table
                ref="table"
                :data="data"
                :loading="isLoading"
                :paginated="true"
                :per-page="perPage.value"
                :show-detail-icon="showDetailIcon"
                :sticky-header="true"
                class="mt-2"
                custom-detail-row
                detail-key="id"
                detailed
                height="600px"
                hoverable
            >
              <!-- campaign -->
              <b-table-column
                  :label="columnsVisible['campaign'].title"
                  field="campaign"
                  sortable
                  width="300"
              >
                <template v-slot:subheading>
                  Итого
                </template>
                <template v-slot="props">
                  <div>
                    <div>
                      <template v-if="showDetailIcon">
                        {{ props.row.campaign }}
                      </template>
                      <template v-else>
                        <a @click="toggle(props.row)">
                          {{ props.row.campaign }}
                        </a>
                      </template>
                    </div>
                  </div>
                </template>
              </b-table-column>

              <!-- shows -->
              <b-table-column
                  :custom-sort="(a,b,isAsc) => customSort(a,b, isAsc, 'shows')"
                  :label="columnsVisible['shows'].title"
                  :visible="columnsVisible['shows'].display"
                  centered
                  field="shows"
                  sortable
              >
                <template v-slot:subheading>
                  {{ columnValueFormat(summaryShows) }}
                </template>
                <template v-slot="props">
                  {{ columnValueFormat(props.row.shows) }}
                </template>
              </b-table-column>

              <!-- clicks -->
              <b-table-column
                  :custom-sort="(a,b,isAsc) => customSort(a,b, isAsc, 'clicks')"
                  :label="columnsVisible['clicks'].title"
                  :visible="columnsVisible['clicks'].display"
                  centered
                  field="clicks"
                  sortable
              >
                <template v-slot:subheading>
                  {{ columnValueFormat(summaryClicks) }}
                </template>
                <template v-slot="props">
                  {{ columnValueFormat(props.row.clicks) }}
                </template>
              </b-table-column>

              <!-- consumption -->
              <b-table-column
                  :custom-sort="(a,b,isAsc) => customSort(a,b, isAsc, 'consumption')"
                  :label="columnsVisible['consumption'].title"
                  :visible="columnsVisible['consumption'].display"
                  centered
                  field="consumption"
                  sortable
              >
                <template v-slot:subheading>
                  {{ columnValueFormat(summaryConsumption) }}
                </template>
                <template v-slot="props">
                  {{ columnValueFormat(props.row.consumption) }}
                </template>
              </b-table-column>

              <!-- avgCpc -->
              <b-table-column
                  :custom-sort="(a,b,isAsc) => customSort(a,b, isAsc, 'avgCpc')"
                  :label="columnsVisible['avgCpc'].title"
                  :visible="columnsVisible['avgCpc'].display"
                  centered
                  field="avgCpc"
                  sortable
              >
                <template v-slot:subheading>
                  {{ columnValueFormat(summaryAvgCpc) }}
                </template>
                <template v-slot="props">
                  {{ columnValueFormat(props.row.avgCpc) }}
                </template>
              </b-table-column>

              <!-- ctr -->
              <b-table-column
                  :custom-sort="(a,b,isAsc) => customSort(a,b, isAsc, 'ctr')"
                  :label="columnsVisible['ctr'].title"
                  :visible="columnsVisible['ctr'].display"
                  centered
                  field="ctr"
                  sortable
              >
                <template v-slot:subheading>
                  {{ columnValueFormat(summaryCtr) }}
                </template>
                <template v-slot="props">
                  {{ columnValueFormat(props.row.ctr) }}
                </template>
              </b-table-column>

              <!-- AvgTrafficVolume -->
              <b-table-column
                  :custom-sort="(a,b,isAsc) => customSort(a,b, isAsc, 'ctr')"
                  :label="columnsVisible['avgTrafficVolume'].title"
                  :visible="columnsVisible['avgTrafficVolume'].display"
                  centered
                  field="avgTrafficVolume"
                  sortable
              >
                <template v-slot:subheading>
                  {{ columnValueFormat(summaryAvgTrafficVolume) }}
                </template>
                <template v-slot="props">
                  {{ columnValueFormat(props.row.avgTrafficVolume) }}
                </template>
              </b-table-column>

              <!-- Analytic block start -->
              <!-- analyticRevenue -->
              <b-table-column
                  :custom-sort="(a,b,isAsc) => customSort(a,b, isAsc, 'analyticRevenue')"
                  :label="columnsVisible['analyticRevenue'].title"
                  :visible="columnsVisible['analyticRevenue'].display"
                  centered
                  field="analyticRevenue"
                  sortable
              >
                <template v-slot:subheading>
                  {{ columnValueFormat(summaryAnalyticRevenue) }}
                </template>
                <template v-slot="props">
                  {{ columnValueFormat(props.row.analyticRevenue) }}
                </template>
              </b-table-column>

              <!-- analyticMarginProfit -->
              <b-table-column
                  :custom-sort="(a,b,isAsc) => customSort(a,b, isAsc, 'analyticMarginProfit')"
                  :label="columnsVisible['analyticMarginProfit'].title"
                  :visible="columnsVisible['analyticMarginProfit'].display"
                  centered
                  field="analyticMarginProfit"
                  sortable
              >
                <template v-slot:subheading>
                  {{ columnValueFormat(summaryAnalyticMarginProfit) }}
                </template>
                <template v-slot="props">
                  {{ columnValueFormat(props.row.analyticMarginProfit) }}
                </template>
              </b-table-column>

              <!-- analyticGoals -->
              <b-table-column
                  :custom-sort="(a,b,isAsc) => customSort(a,b, isAsc, 'analyticGoals')"
                  :label="columnsVisible['analyticGoals'].title"
                  :visible="columnsVisible['analyticGoals'].display"
                  centered
                  field="analyticGoals"
                  sortable
              >
                <template v-slot:subheading>
                  {{ columnValueFormat(summaryAnalyticGoals) }}
                </template>
                <template v-slot="props">
                  {{ columnValueFormat(props.row.analyticGoals) }}
                </template>
              </b-table-column>

              <!-- analyticCr -->
              <b-table-column
                  :custom-sort="(a,b,isAsc) => customSort(a,b, isAsc, 'analyticCr')"
                  :label="columnsVisible['analyticCr'].title"
                  :visible="columnsVisible['analyticCr'].display"
                  centered
                  field="analyticCr"
                  sortable
              >
                <template v-slot:subheading>
                  {{ columnValueFormat(summaryAnalyticCr) }}
                </template>
                <template v-slot="props">
                  {{ columnValueFormat(props.row.analyticCr) }}
                </template>
              </b-table-column>

              <!-- analyticCost -->
              <b-table-column
                  :custom-sort="(a,b,isAsc) => customSort(a,b, isAsc, 'analyticCost')"
                  :label="columnsVisible['analyticCost'].title"
                  :visible="columnsVisible['analyticCost'].display"
                  centered
                  field="analyticCost"
                  sortable
              >
                <template v-slot:subheading>
                  {{ columnValueFormat(summaryAnalyticCost) }}
                </template>
                <template v-slot="props">
                  {{ columnValueFormat(props.row.analyticCost) }}
                </template>
              </b-table-column>

              <!-- analyticRoi -->
              <b-table-column
                  :custom-sort="(a,b,isAsc) => customSort(a,b, isAsc, 'analyticRoi')"
                  :label="columnsVisible['analyticRoi'].title"
                  :visible="columnsVisible['analyticRoi'].display"
                  centered
                  field="analyticRoi"
                  sortable
              >
                <template v-slot:subheading>
                  {{ columnValueFormat(summaryAnalyticRoi) }}
                </template>
                <template v-slot="props">
                  {{ columnValueFormat(props.row.analyticRoi) }}
                </template>
              </b-table-column>

              <!-- Analytic block end -->

              <!-- CRM block start -->
              <!-- profit -->
              <b-table-column
                  :custom-sort="(a,b,isAsc) => customSort(a,b, isAsc, 'profit')"
                  :label="columnsVisible['profit'].title"
                  :visible="columnsVisible['profit'].display"
                  centered
                  field="profit"
                  sortable
              >
                <template v-slot:subheading>
                  {{ columnValueFormat(summaryProfit) }}
                </template>
                <template v-slot="props">
                  {{ columnValueFormat(props.row.profit) }}
                </template>
              </b-table-column>

              <!-- marginProfit -->
              <b-table-column
                  :custom-sort="(a,b,isAsc) => customSort(a,b, isAsc, 'marginProfit')"
                  :label="columnsVisible['marginProfit'].title"
                  :visible="columnsVisible['marginProfit'].display"
                  centered
                  field="profit"
                  sortable
              >
                <template v-slot:subheading>
                  {{ columnValueFormat(summaryMarginProfit) }}
                </template>
                <template v-slot="props">
                  {{ columnValueFormat(props.row.marginProfit) }}
                </template>
              </b-table-column>

              <!-- numberOrder -->
              <b-table-column
                  :custom-sort="(a,b,isAsc) => customSort(a,b, isAsc, 'numberOrders')"
                  :label="columnsVisible['numberOrders'].title"
                  :visible="columnsVisible['numberOrders'].display"
                  centered
                  field="numberOrders"
                  sortable
              >
                <template v-slot:subheading>
                  {{ columnValueFormat(summaryNumberOrders) }}
                </template>
                <template v-slot="props">
                  {{ columnValueFormat(props.row.numberOrders) }}
                </template>
              </b-table-column>

              <!-- cr -->
              <b-table-column
                  :custom-sort="(a,b,isAsc) => customSort(a,b, isAsc, 'cr')"
                  :label="columnsVisible['cr'].title"
                  :visible="columnsVisible['cr'].display"
                  centered
                  field="cr"
                  sortable
              >
                <template v-slot:subheading>
                  {{ columnValueFormat(summaryCr) }}
                </template>
                <template v-slot="props">
                  {{ columnValueFormat(props.row.cr) }}
                </template>
              </b-table-column>

              <!-- cost -->
              <b-table-column
                  :custom-sort="(a,b,isAsc) => customSort(a,b, isAsc, 'cost')"
                  :label="columnsVisible['cost'].title"
                  :visible="columnsVisible['cost'].display"
                  centered
                  field="cost"
                  sortable
              >
                <template v-slot:subheading>
                  {{ columnValueFormat(summaryCost) }}
                </template>
                <template v-slot="props">
                  {{ columnValueFormat(props.row.cost) }}
                </template>
              </b-table-column>

              <!-- roi -->
              <b-table-column
                  :custom-sort="(a,b,isAsc) => customSort(a,b, isAsc, 'roi')"
                  :label="columnsVisible['roi'].title"
                  :visible="columnsVisible['roi'].display"
                  centered
                  field="roi"
                  sortable
              >
                <template v-slot:subheading>
                  {{ columnValueFormat(summaryRoi) }}
                </template>
                <template v-slot="props">
                  {{ columnValueFormat(props.row.roi) }}
                </template>
              </b-table-column>
              <!-- CRM block end -->

              <template
                  slot="detail"
                  slot-scope="props"
              >

                <tr
                    v-for="item in props.row.items"
                    :key="item.id"
                >
                  <td v-if="showDetailIcon"/>
                  <td>
                    &nbsp;&nbsp;&nbsp;&nbsp;{{ item.criterion }}
                  </td>
                  <td
                      v-show="columnsVisible['shows'].display"
                      class="has-text-centered"
                  >
                    {{ item.shows }}
                  </td>
                  <td
                      v-show="columnsVisible['clicks'].display"
                      class="has-text-centered"
                  >
                    {{ item.clicks }}
                  </td>
                  <td
                      v-show="columnsVisible['consumption'].display"
                      class="has-text-centered"
                  >
                    {{ columnValueFormat(item.consumption) }}
                  </td>
                  <td
                      v-show="columnsVisible['avgCpc'].display"
                      class="has-text-centered"
                  >
                    {{ columnValueFormat(item.avgCpc) }}
                  </td>
                  <td
                      v-show="columnsVisible['ctr'].display"
                      class="has-text-centered"
                  >
                    {{ columnValueFormat(item.ctr) }}
                  </td>
                  <td
                      v-show="columnsVisible['avgTrafficVolume'].display"
                      class="has-text-centered"
                  >
                    {{ columnValueFormat(item.avgTrafficVolume) }}
                  </td>
                  <td
                      v-show="columnsVisible['analyticRevenue'].display"
                      class="has-text-centered"
                  >
                    {{ columnValueFormat(item.analyticRevenue) }}
                  </td>
                  <td
                      v-show="columnsVisible['analyticMarginProfit'].display"
                      class="has-text-centered"
                  >
                    {{ columnValueFormat(item.analyticMarginProfit) }}
                  </td>
                  <td
                      v-show="columnsVisible['analyticGoals'].display"
                      class="has-text-centered"
                  >
                    {{ item.analyticGoals }}
                  </td>
                  <td
                      v-show="columnsVisible['analyticCr'].display"
                      class="has-text-centered"
                  >
                    {{ columnValueFormat(item.analyticCr) }}
                  </td>
                  <td
                      v-show="columnsVisible['analyticCost'].display"
                      class="has-text-centered"
                  >
                    {{ columnValueFormat(item.analyticCost) }}
                  </td>
                  <td
                      v-show="columnsVisible['analyticRoi'].display"
                      class="has-text-centered"
                  >
                    {{ columnValueFormat(item.analyticRoi) }}
                  </td>
                  <td
                      v-show="columnsVisible['profit'].display"
                      class="has-text-centered"
                  >
                    {{ columnValueFormat(item.profit) }}
                  </td>
                  <td
                      v-show="columnsVisible['marginProfit'].display"
                      class="has-text-centered"
                  >
                    {{ columnValueFormat(item.marginProfit) }}
                  </td>
                  <td
                      v-show="columnsVisible['numberOrders'].display"
                      class="has-text-centered"
                  >
                    {{ item.numberOrders }}
                  </td>
                  <td
                      v-show="columnsVisible['cr'].display"
                      class="has-text-centered"
                  >
                    {{ columnValueFormat(item.cr) }}
                  </td>
                  <td
                      v-show="columnsVisible['cost'].display"
                      class="has-text-centered"
                  >
                    {{ columnValueFormat(item.cost) }}
                  </td>
                  <td
                      v-show="columnsVisible['roi'].display"
                      class="has-text-centered"
                  >
                    {{ columnValueFormat(item.roi) }}
                  </td>
                </tr>
              </template>
            </b-table>
          </div>
        </b-col>
      </transition>

      <transition
          mode="out-in"
          name="fade"
      >
        <b-col
            v-show="emptyStats === 'NO_STATS'"
            cols="12"
        >
          <div class="misc-inner p-2 p-sm-3">
            <div class="w-100 text-center">
              <h2 class="mb-1">
                На проекте нет статистики
              </h2>
              <p class="mb-3">
                Подключите интеграции к вашему проекту
              </p>

              <b-img
                  :src="downImg"
                  alt=""
                  fluid
              />
            </div>
          </div>
        </b-col>
      </transition>
    </b-row>
    <b-row
        v-if="activeProjectId == null"
        class="match-height"
    >

      <b-col cols="12">
        <div class="misc-inner p-2 p-sm-3">
          <div class="w-100 text-center">
            <h2 class="mb-1">
              Выберите проект или создайте новый
            </h2>
            <h4 class="mb-3">
              <b-link @click="tourStartAnalytics">
                Как это сделать?
              </b-link>
            </h4>
            <b-img
                :src="downImg"
                alt=""
                fluid
            />
          </div>
        </div>
      </b-col>
    </b-row>
    <app-tour
        :steps="steps"
        name="test"
    />
  </section>
</template>
<script>
import _ from 'lodash'
import { kFormatter } from '@core/utils/filter'
import AppTour from '@core/components/app-tour/AppTour.vue'
import {
  BBadge,
  BButton,
  BButtonGroup,
  BCard,
  BCardBody,
  BCardHeader,
  BCardSubTitle,
  BCardTitle,
  BCol,
  BFormGroup,
  BFormRow,
  BImg,
  BLink,
  BOverlay,
  BRow,
} from 'bootstrap-vue'
import VueApexCharts from 'vue-apexcharts'
import flatPickr from 'vue-flatpickr-component'
// eslint-disable-next-line import/extensions
import { Russian } from 'flatpickr/dist/l10n/ru.js'
import vSelect from 'vue-select'
import OpenIndicator from '@core/components/vue-select/OpenIndicator.vue'
import Ripple from 'vue-ripple-directive'
import apexChatData from '@/views/charts-and-maps/charts/apex-chart/apexChartData'

const dateNow = new Date()
const dateNowPrev = new Date(new Date(dateNow).setDate(dateNow.getDate() - 7))

// eslint-disable-next-line no-extend-native
Date.prototype.yyyymmdd = function () {
  const mm = this.getMonth() + 1
  const dd = this.getDate()

  // eslint-disable-next-line radix
  return [this.getFullYear(),
    (mm > 9 ? '' : '0') + mm,
    (dd > 9 ? '' : '0') + dd,
  ].join('')
}

const chartColors = {
  column: {
    series1: '#826af9',
    series2: '#d2b0ff',
    bg: '#f8d3ff',
  },
  success: {
    shade_100: '#7eefc7',
    shade_200: '#06774f',
  },
  donut: {
    series1: '#ffe700',
    series2: '#00d4bd',
    series3: '#826bf8',
    series4: '#2b9bf4',
    series5: '#FFA1A1',
  },
  area: {
    series10: '#a9390c',
    series9: '#316b6b',
    series8: '#e26572',
    series7: '#e369b6',
    series6: '#5dd2db',
    series5: '#7253d0',
    series4: '#4f82cf',
    series3: '#8BC554',
    series2: '#FECC35',
    series1: '#FC4526',
  },
}

const chartLegends = [
  'Показы',
  'Клики',
  'Расходы (руб.)',
  'Средняя стоимость клика',
  'Ctr',
  'Средний объем трафика',
  'Выручка (аналитика)',
  'Маржинальная прибыль (аналитика)',
  'Кол-во заказов (аналитика)',
  'Cr (аналитика)',
  'Стоимость заказов (аналитика)',
  'Roi (аналитика)',
  'Выручка (CRM)',
  'Маржинальная прибыль (CRM)',
  'Кол-во заказов (CRM)',
  'Cr (CRM)',
  'Стоимость заказов (CRM)',
  'Roi (CRM)',
]
const initialLegends = [
  'Расходы (руб.)',
  'Кол-во заказов (аналитика)',
]

let chosenLegends = []

vSelect.props.components.default = () => ({ OpenIndicator })

export default {
  components: {
    BCard,
    AppTour,
    vSelect,
    VueApexCharts,
    BLink,
    BCardHeader,
    BCardBody,
    BButton,
    BCardTitle,
    BButtonGroup,
    BFormRow,
    BFormGroup,
    BImg,
    BCardSubTitle,
    flatPickr,
    BOverlay,
    BRow,
    BBadge,
    BCol,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      steps: [
        {
          target: '#choose-project',
          header: {
            title: 'Выберите проект',
          },
          content: 'Откройте список для выбора проекта',
        },
      ],
      data: [],
      emptyStats: null,
      emptyGraph: null,
      collapseChart: false,
      activeSeriesIndices: [],
      collapseChartButtonName: 'Свернуть график',
      notFoundProject: false,
      perPage: {
        value: 30,
        text: '30 на странице',
      },
      downImg: require('@/assets/images/pages/coming-soon.svg'),
      campaignFormatLabels: ['кампания', 'кампании', 'кампаний'],
      campaign: [],
      campaignOptions: [],
      chosenCampaigns: [],
      showChartLoading: false,
      changedSeries: [],
      changedChartOptions: {
        chart: {
          toolbar: {
            show: false,
          },
          type: 'line',
          zoom: {
            enabled: false,
          },
          events: {
            legendClick: (chartContext, seriesIndex) => {

              const activeIndex = this.activeSeriesIndices.findIndex(s => s === seriesIndex)
              if (activeIndex === -1) {
                if (this.activeSeriesIndices.length > 1) {
                  chartContext.toggleSeries(this.changedSeries[this.activeSeriesIndices[0]].name)
                  this.activeSeriesIndices.shift()
                }

                this.activeSeriesIndices.push(seriesIndex)

                // this.changedSeries[this.activeSeriesIndices[0]].yAxis = 0
                // this.changedChartOptions.yaxis[this.activeSeriesIndices[0]].title = { text: this.changedSeries[this.activeSeriesIndices[0]].name }
                this.changedChartOptions.yaxis[this.activeSeriesIndices[0]].opposite = false
                this.changedChartOptions.yaxis[this.activeSeriesIndices[0]].seriesName = this.changedSeries[this.activeSeriesIndices[0]].name

                if (this.activeSeriesIndices.length > 1) {
                  // this.changedSeries[this.activeSeriesIndices[1]].yAxis = 1
                  // this.changedChartOptions.yaxis[this.activeSeriesIndices[1]].title = { text: this.changedSeries[this.activeSeriesIndices[1]].name }
                  this.changedChartOptions.yaxis[this.activeSeriesIndices[1]].opposite = true
                  this.changedChartOptions.yaxis[this.activeSeriesIndices[1]].seriesName = this.changedSeries[this.activeSeriesIndices[1]].name
                }
                chartContext.updateOptions({
                  series: this.changedSeries,
                  yaxis: this.changedChartOptions.yaxis,
                })
              } else {
                this.activeSeriesIndices.splice(activeIndex, 1)
              }
            },
            mounted: chartContext => {
              const mappedArr = this.changedSeries.map((s, i) => i)
              this.activeSeriesIndices = mappedArr.filter((s, i) => !chartContext.w.globals.collapsedSeriesIndices.includes(i))
              if (this.activeSeriesIndices.length > 2) {
                this.changedSeries.forEach((s, index) => {
                  if (initialLegends.includes(s.name)) return
                  chartContext.toggleSeries(chartContext.w.config.series[index].name)
                })
              }
              this.activeSeriesIndices = this.activeSeriesIndices.filter(i => !chartContext.w.globals.collapsedSeriesIndices.includes(i))
            },
          },
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          show: true,
          curve: 'straight',
          width: 3,
        },
        legend: {
          show: true,
          position: 'top',
          horizontalAlign: 'left',
          fontSize: '14px',
          fontFamily: 'Montserrat',
        },
        grid: {
          xaxis: {
            lines: {
              show: true,
            },
          },
        },
        xaxis: {
          categories: [],
        },
        yaxis: [
          { // Показы
            labels: {
              formatter(value) {
                return value
              },
            },
          },
          { // Клики
            labels: {
              formatter(value) {
                return value
              },
            },
          },
          { // Расходы
            labels: {
              formatter(value) {
                return value
              },
            },
            opposite: false,
          },
          { // AvgCpc
            labels: {
              formatter(value) {
                return value
              },
            },
          },
          { // Ctr
            labels: {
              formatter(value) {
                return value
              },
            },
          },
          { // AvgTrafficVolume
            labels: {
              formatter(value) {
                return value
              },
            },
          },
          { // Выручка (аналитика)
            labels: {
              formatter(value) {
                return value
              },
            },
          },
          { // Маржинальная прибыль (аналитика)
            labels: {
              formatter(value) {
                return value
              },
            },
          },
          { // Кол-во заказов (аналитика)
            labels: {
              formatter(value) {
                return value
              },
            },
            opposite: true,
          },
          { // Cr (аналитика)
            labels: {
              formatter(value) {
                return value
              },
            },
          },
          { // Стоимость заказов (аналитика)
            labels: {
              formatter(value) {
                return value
              },
            },
          },
          { // Roi (аналитика)
            labels: {
              formatter(value) {
                return value
              },
            },
          },
          { // Выручка
            labels: {
              formatter(value) {
                return value
              },
            },
          },
          { // Маржинальная прибыль
            labels: {
              formatter(value) {
                return value
              },
            },
          },
          { // Кол-во заказов
            labels: {
              formatter(value) {
                return value
              },
            },
          },
          { // Cr (аналитика)
            labels: {
              formatter(value) {
                return value
              },
            },
          },
          { // Стоимость заказов (аналитика)
            labels: {
              formatter(value) {
                return value
              },
            },
          },
          { // Roi (аналитика)
            labels: {
              formatter(value) {
                return value
              },
            },
          },
        ],
        fill: {
          opacity: 0.3,
          type: 'solid',
        },
        tooltip: {
          offsetY: 20,
        },
        colors: [
          chartColors.area.series3,
          chartColors.area.series2,
          chartColors.area.series1,
          chartColors.area.series4,
          chartColors.area.series5,
          chartColors.area.series6,
          chartColors.area.series7,
          chartColors.area.series8,
          chartColors.area.series9,
          chartColors.area.series10,
        ],
      },
      optionsPerPages: [
        {
          value: 10,
          text: '10 на странице',
        },
        {
          value: 30,
          text: '30 на странице',
        },
        {
          value: 50,
          text: '50 на странице',
        },
        {
          value: 100,
          text: '50 на странице',
        },
        {
          value: 250,
          text: '250 на странице',
        },
        {
          value: 500,
          text: '500 на странице',
        },
      ],
      useTransition: false,
      isLoading: false,
      summaryShows: 0,
      summaryClicks: 0,
      summaryConsumption: 0,
      summaryCtr: 0,
      summaryAvgCpc: 0,
      summaryAvgTrafficVolume: 0,
      summaryAnalyticRevenue: 0,
      summaryAnalyticMarginProfit: 0,
      summaryAnalyticGoals: 0,
      summaryAnalyticCost: 0,
      summaryAnalyticCr: 0,
      summaryAnalyticRoi: 0,
      summaryProfit: 0,
      summaryMarginProfit: 0,
      summaryNumberOrders: 0,
      summaryCost: 0,
      summaryCr: 0,
      summaryRoi: 0,
      columnsVisible: {
        campaign: {
          title: 'Кампании',
          display: true,
          subheading: 'Всего:',
          type: 'campaign',
        },
        shows: {
          title: 'Показы',
          display: true,
          type: 'Трафик',
        },
        clicks: {
          title: 'Клики',
          display: true,
          type: 'Трафик'
        },
        consumption: {
          title: 'Расходы',
          display: true,
          type: 'Трафик'
        },
        ctr: {
          title: 'Ctr',
          display: true,
          type: 'Трафик'
        },
        avgCpc: {
          title: 'Средняя стоимость клика',
          display: true,
          type: 'Трафик'
        },
        avgTrafficVolume: {
          title: 'Средний объем трафика',
          display: true,
          type: 'Трафик'
        },
        analyticRevenue: {
          title: 'Выручка (аналитика)',
          display: false,
          type: 'Аналитика'
        },
        analyticMarginProfit: {
          title: 'Маржинальная прибыль (аналитика)',
          display: false,
          type: 'Аналитика'
        },
        analyticGoals: {
          title: 'Кол-во заказов (аналитика)',
          display: true,
          type: 'Аналитика'
        },
        analyticCost: {
          title: 'Стоимость заказов (аналитика)',
          display: true,
          type: 'Аналитика'
        },
        analyticCr: {
          title: 'Cr (аналитика)',
          display: false,
          type: 'Аналитика'
        },
        analyticRoi: {
          title: 'Roi (аналитика)',
          display: false,
          type: 'Аналитика'
        },
        profit: {
          title: 'Выручка (CRM)',
          display: false,
          type: 'Crm'
        },
        marginProfit: {
          title: 'Маржинальная прибыль (CRM)',
          display: false,
          type: 'Crm'
        },
        numberOrders: {
          title: 'Кол-во заказов (CRM)',
          display: false,
          type: 'Crm'
        },
        cost: {
          title: 'Стоимость заказов (CRM)',
          display: false,
          type: 'Crm'
        },
        cr: {
          title: 'Cr (CRM)',
          display: false,
          type: 'Crm'
        },
        roi: {
          title: 'Roi (CRM)',
          display: false,
          type: 'Crm'
        },
      },
      showDetailIcon: true,
      datesTest: [],
      defaultOpenedDetails: [1],
      items: [],
      rangeData: [dateNowPrev, dateNow],
      changedRangeData: [],
      apexChatData,
      saveStatisticData: [],
      checkSortingAttr: null,
      isShowColumnsRow: false,
      dataPickerConfig: {
        mode: 'range',
        locale: Russian,
        dateFormat: 'd.m.Y',
      },
    }
  },
  computed: {
    localStorageActiveProject() {
      return localStorage.getItem('active_project_id')
    },
    groupedColumns() {
      function getPredicate() {
        return v => v.type !== 'campaign'
      }

      const columns = _.groupBy(_.filter(this.columnsVisible,
          getPredicate()), 'type')
      return columns
    },
    isChanged() {
      return this.$store.state.project.isChanged
    },
    activeProjectId() {
      return this.$store.state.project.activeProjectId
    },
    activeProject() {
      return this.$store.state.project.projects.find(c => c.id === this.activeProjectId)
    },
    tableData() {
      return this.$store.state.statistic.tableData
    },
    graphData() {
      return this.$store.state.statistic.graphData
    },
  },
  watch: {
    chosenCampaigns() {
      if (this.chosenCampaigns.length === 0) {
        this.data = JSON.parse(JSON.stringify(this.saveStatisticData))
      } else {
        const campaignNames = this.chosenCampaigns.map(c => c.text)
        this.data = JSON.parse(JSON.stringify(this.saveStatisticData))
            .filter(stat => campaignNames.includes(stat.campaign))
            .filter((x, i, a) => a.indexOf(x) === i)
      }
    },
    activeProjectId(oldState, newState) {
      let isChanged = false
      if (newState == null || oldState !== newState) {
        isChanged = true
      }
      this.loadStats(this.rangeData, isChanged)
    },
  },
  mounted() {
    if (this.activeProjectId) {
      this.loadStats(this.rangeData)
    }
  },
  methods: {
    kFormatter,
    testSignalr() {
      // logout api
      this.$http.get('logger/signal')
          // eslint-disable-next-line no-unused-vars
          .then(response => {
          })
    },
    calculateAverage(array) {
      let total = 0
      let count = 0

      array.forEach((item, index) => {
        total += item
        count++
      })

      return total / count
    },
    tourStartAnalytics() {
      this.$tours.test.start()
    },
    isDisabledColumns(val) {
      if (val === 'Crm') {
        return this.activeProject != null ? this.activeProject.isActiveCrm : true
      }
      if (val === 'Трафик') {
        return this.activeProject != null ? this.activeProject.isActiveTraffic : true
      }
      if (val === 'Аналитика') {
        return this.activeProject != null ? this.activeProject.isActiveAnalytic : true
      }
    },
    columnValueFormat(val) {
      if (val === null || val === '-' || val === undefined || isNaN(val)) {
        return '-'
      }

      if (val === 0) {
        return 0
      }

      if (Math.round(+val) !== +val) {
        return (val.toFixed(2)).toLocaleString()
      }

      return (+val).toLocaleString()
    },
    checkNeedLoadGraph(selectedDates) {
      return (this.changedRangeData[0] !== selectedDates[0]
              || this.changedRangeData[1] !== selectedDates[1])
          && this.changedRangeData != null
    },
    changeDatesRange(selectedDates) {
      const isChangedNeed = this.checkNeedLoadGraph(selectedDates)
      if (isChangedNeed) {
        this.changedSeries = [{
          name: null,
          data: [],
        }]
        this.changedRangeData = selectedDates
        this.loadStats(selectedDates)
      }
    },
    changeGroupByGraph(groupByValue) {
      this.showChartLoading = true
      // this.$store.commit('statistic/CLEAR_GRAPH_DATA')
      const startDate = this.changedRangeData[0]
      const endDate = this.changedRangeData[1]

      const isDate = this.changedRangeData != null
          && startDate != null
          && endDate != null

      if (isDate) {
        this.buildGraph({
          dateGroupBy: groupByValue,
          projectId: this.activeProjectId,
          startDate: (+startDate.yyyymmdd()),
          endDate: (+endDate.yyyymmdd()),
        })
      }
    },
    collapseExpandChart() {
      this.collapseChart = !this.collapseChart
      if (this.collapseChart) {
        this.collapseChartButtonName = 'Показать график'
      } else {
        this.collapseChartButtonName = 'Свернуть график'
      }
    },
    customSort(a, b, isAsc, val) {
      if (!isAsc) {
        a.items.sort((ai, bi) => Number(bi[val]) - Number(ai[val]))
        b.items.sort((ai, bi) => Number(bi[val]) - Number(ai[val]))
        return b[val] - a[val]
      }
      a.items.sort((ai, bi) => Number(ai[val]) - Number(bi[val]))
      b.items.sort((ai, bi) => Number(ai[val]) - Number(bi[val]))
      return a[val] - b[val]
    },
    loadStats(dateRange, isChanged) {
      this.isLoading = true
      this.emptyStats = null
      this.emptyGraph = null
      this.showChartLoading = true
      this.data = []
      this.saveStatisticData = []
      this.campaignOptions = []
      this.changedRangeData = dateRange

      if (dateRange != null) {
        const startDate = dateRange[0]
        const endDate = dateRange[1]

        if (startDate != null && endDate != null) {
          const filterGraphData = {
            dateGroupBy: 0,
            projectId: this.activeProjectId,
            startDate: (+startDate.yyyymmdd()),
            endDate: (+endDate.yyyymmdd()),
          }

          const filterTableData = {
            projectId: this.activeProjectId,
            startDate: (+startDate.yyyymmdd()),
            endDate: (+endDate.yyyymmdd()),
          }

          const isDefaultRange = (+startDate.yyyymmdd()) === (+dateNowPrev.yyyymmdd())
              && (+endDate.yyyymmdd()) === (+dateNow.yyyymmdd())

          const isNeedBuild = this.tableData != null
              && this.graphData != null && isDefaultRange

          if (!isChanged && isNeedBuild) {
            console.log('calculateGraphData')
            this.calculateGraphData(this.graphData, filterGraphData)
            this.calculateTableData(this.tableData)
          } else {
            console.log('buildGraph')
            this.buildGraph(filterGraphData, isDefaultRange)
            this.buildTable(filterTableData, isDefaultRange)
          }

          this.$store.commit('project/UPDATE_LOADING_PROJECT', false)
        }
      }
    },
    calculateGraphData(dataForParse, filterGraphData) {
      const dataForChart = _.values(dataForParse)
      const dates = dataForChart.map(a => new Date(a.date))

      const dataShowsSeries = dataForChart.map(d => d.shows !== null ? d.shows : 0)
      const dataClicksSeries = dataForChart.map(d => d.clicks !== null ? d.clicks : 0)
      const dataConsumptionsSeries = dataForChart.map(d => d.consumption !== null ? this.columnValueFormat(d.consumption) : 0)
      const dataCtrSeries = dataForChart.map(d => d.ctr !== null ? this.columnValueFormat(d.ctr) : 0)
      const dataAvgCpcSeries = dataForChart.map(d => d.avgCpc !== null ? d.avgCpc : 0)
      const dataAvgTrafficVolumeSeries = dataForChart.map(d => d.avgTrafficVolume !== null ? d.avgTrafficVolume : 0)

      const dataAnalyticRevenueSeries = dataForChart.map(d => d.analyticRevenue !== null ? d.analyticRevenue : 0)
      const dataAnalyticMarginProfitSeries = dataForChart.map(d => d.analyticMarginProfit !== null ? d.analyticMarginProfit : 0)
      const dataAnalyticGoalsSeries = dataForChart.map(d => d.analyticGoals !== null ? d.analyticGoals : 0)
      const dataAnalyticCostSeries = dataForChart.map(d => d.analyticCost !== null ? d.analyticCost : 0)
      const dataAnalyticCrSeries = dataForChart.map(d => d.analyticCr !== null ? d.analyticCr : 0)
      const dataAnalyticRoiSeries = dataForChart.map(d => d.analyticRoi !== null ? d.analyticRoi : 0)

      const dataProfitSeries = dataForChart.map(d => d.profit !== null ? d.profit : 0)
      const dataMarginProfitSeries = dataForChart.map(d => d.marginProfit !== null ? d.marginProfit : 0)
      const dataNumberOrdersSeries = dataForChart.map(d => d.numberOrders !== null ? d.numberOrders : 0)
      const dataCostSeries = dataForChart.map(d => d.cost !== null ? d.cost : 0)
      const dataCrSeries = dataForChart.map(d => d.cr !== null ? d.cr : 0)
      const dataRoiSeries = dataForChart.map(d => d.roi !== null ? d.roi : 0)

      this.changedChartOptions = {
        ...this.changedChartOptions,
        ...{
          xaxis: {
            labels: {
              format: 'dd MMM',
              formatter(value) {
                let formatLocaleDate = {
                  month: 'numeric',
                  day: 'numeric',
                }
                switch (filterGraphData.dateGroupBy) {
                  case 1:
                    formatLocaleDate = {
                      month: 'short',
                    }
                    break
                  case 2:
                    formatLocaleDate = {
                      year: 'numeric',
                    }
                    break
                  default:
                    break
                }

                return new Date(value)
                    .toLocaleDateString('ru', formatLocaleDate)
              },
            },
            categories: dates,
            tickAmount: Math.round(dates.length / 2),
          },
          // yaxis: [],
          tooltip: {
            x: {
              formatter(value) {
                let formatLocaleDate = {
                  month: 'short',
                  day: 'numeric',
                  year: 'numeric',
                }

                switch (filterGraphData.dateGroupBy) {
                  case 1:
                    formatLocaleDate = {
                      month: 'short',
                    }
                    break
                  case 2:
                    formatLocaleDate = {
                      year: 'numeric',
                    }
                    break
                  default:
                    break
                }

                return new Date(dates[value - 1])
                    .toLocaleDateString('ru', formatLocaleDate)
              },
            },
          },
        },
      }

      const showsChart = {
        name: this.columnsVisible.shows.title,
        data: dataShowsSeries,
        // yAxisIndex: 0,
      }
      const clicksChart = {
        name: this.columnsVisible.clicks.title,
        data: dataClicksSeries,
        // yAxisIndex: 1,
      }
      const consumptionsChart = {
        name: `${this.columnsVisible.consumption.title} (руб.)`,
        data: dataConsumptionsSeries,
        // yAxisIndex: 1,
      }
      const ctrChart = {
        name: `${this.columnsVisible.ctr.title}`,
        data: dataCtrSeries,
        // yAxisIndex: 1,
      }
      const avgCpcChart = {
        name: `${this.columnsVisible.avgCpc.title}`,
        data: dataAvgCpcSeries,
        // yAxisIndex: 0,
      }
      const avgTrafficVolumeChart = {
        name: `${this.columnsVisible.avgTrafficVolume.title}`,
        data: dataAvgTrafficVolumeSeries,
        // yAxisIndex: 1,
      }

      const analyticRevenueChart = {
        name: `${this.columnsVisible.analyticRevenue.title}`,
        data: dataAnalyticRevenueSeries,
        // yAxisIndex: 0,
      }
      const analyticMarginProfitChart = {
        name: `${this.columnsVisible.analyticMarginProfit.title}`,
        data: dataAnalyticMarginProfitSeries,
        // yAxisIndex: 1,
      }
      const analyticGoalsChart = {
        name: `${this.columnsVisible.analyticGoals.title}`,
        data: dataAnalyticGoalsSeries,
        // yAxisIndex: 0,
      }
      const analyticCostChart = {
        name: `${this.columnsVisible.analyticCost.title}`,
        data: dataAnalyticCostSeries,
        // yAxisIndex: 1,
      }
      const analyticCrChart = {
        name: `${this.columnsVisible.analyticCr.title}`,
        data: dataAnalyticCrSeries,
        // yAxisIndex: 0,
      }
      const analyticRoiChart = {
        name: `${this.columnsVisible.analyticRoi.title}`,
        data: dataAnalyticRoiSeries,
        // yAxisIndex: 1,
      }

      const profitChart = {
        name: `${this.columnsVisible.profit.title}`,
        data: dataProfitSeries,
        // yAxisIndex: 0,
      }
      const marginProfitChart = {
        name: `${this.columnsVisible.marginProfit.title}`,
        data: dataMarginProfitSeries,
        // yAxisIndex: 1,
      }
      const numberOrdersChart = {
        name: `${this.columnsVisible.numberOrders.title}`,
        data: dataNumberOrdersSeries,
        // yAxisIndex: 0,
      }
      const costChart = {
        name: `${this.columnsVisible.cost.title}`,
        data: dataCostSeries,
        // yAxisIndex: 1,
      }
      const crChart = {
        name: `${this.columnsVisible.cr.title}`,
        data: dataCrSeries,
        // yAxisIndex: 0,
      }

      const roiChart = {
        name: `${this.columnsVisible.roi.title}`,
        data: dataRoiSeries,
        // yAxisIndex: 1,
      }

      this.changedSeries = [
        showsChart,
        clicksChart,
        consumptionsChart,
        avgCpcChart,
        ctrChart,
        avgTrafficVolumeChart,

        analyticRevenueChart,
        analyticMarginProfitChart,
        analyticGoalsChart,
        analyticCrChart,
        analyticCostChart,
        analyticRoiChart,

        profitChart,
        marginProfitChart,
        numberOrdersChart,
        crChart,
        costChart,
        roiChart,
      ]
      this.showChartLoading = false

      this.$refs.chart.chart.render()
    },
    buildGraph(filterGraphData, isDefaultRange) {
      this.$store.dispatch('statistic/fetchGraphStatistic', filterGraphData)
          .then(response => {
            // TO-DO: NEED refactoring
            const dataForParse = response.data
            if (dataForParse === 'NO_STATS') {
              this.emptyGraph = dataForParse
            } else {
              if (isDefaultRange) {
                this.$store.commit('statistic/LOAD_GRAPH_DATA', dataForParse)
              }
              this.calculateGraphData(dataForParse, filterGraphData)
            }
          })
    },
    calculateDependedFields(stat) {
      // eslint-disable-next-line radix

      stat.ctr = stat.shows !== 0
          ? ((parseInt(stat.clicks) / parseInt(stat.shows)) * 100) : '-'

      stat.avgCpc = stat.clicks !== 0
          ? ((parseFloat(stat.consumption) / parseInt(stat.clicks))) : '-'

      // calculate CRM values
      stat.roi = stat.consumption !== 0
          ? ((parseFloat(stat.profit) - parseFloat(stat.consumption)) / parseFloat(stat.consumption)) * 100 : '-'

      stat.cr = stat.clicks !== 0
          ? ((parseFloat(stat.numberOrders) / parseInt(stat.clicks)) * 100) : '-'

      stat.cost = stat.numberOrders !== 0
          ? ((parseFloat(stat.consumption) / parseFloat(stat.numberOrders))) : '-'

      // calculate Analytic values
      stat.analyticRoi = stat.consumption !== 0
          ? ((parseFloat(stat.analyticRevenue) - parseFloat(stat.consumption)) / parseFloat(stat.consumption)) * 100 : '-'

      stat.analyticCr = stat.clicks !== 0
          ? ((parseFloat(stat.analyticGoals) / parseInt(stat.clicks)) * 100) : '-'

      stat.analyticCost = stat.analyticGoals !== 0
          ? ((parseFloat(stat.consumption) / parseFloat(stat.analyticGoals))) : '-'
    },
    calculateTableData(dataForParse) {
      // table parsed
      let increment = 0

      this.summaryShows = 0
      this.summaryClicks = 0
      this.summaryConsumption = 0
      this.summaryAvgCpc = 0
      this.summaryCtr = 0
      this.summaryAvgTrafficVolume = 0
      this.summaryAnalyticRevenue = 0
      this.summaryAnalyticMarginProfit = 0
      this.summaryAnalyticGoals = 0
      this.summaryAnalyticCost = 0
      this.summaryAnalyticCr = 0
      this.summaryAnalyticRoi = 0
      this.summaryProfit = 0
      this.summaryMarginProfit = 0
      this.summaryNumberOrders = 0
      this.summaryCost = 0
      this.summaryCr = 0
      this.summaryRoi = 0

      let summarySubAvgTrafficVolumes = []

      Object.keys(dataForParse)
          .forEach(key => {
            const statItem = dataForParse[key]

            const stat = {
              id: 0,
              campaign: key,
              shows: 0,
              clicks: 0,
              consumption: 0,
              avgCpc: 0,
              ctr: 0,
              marginProfit: 0,
              profit: 0,
              cost: 0,
              cr: 0,
              numberOrders: 0,
              analyticRevenue: 0,
              analyticGoals: 0,
              avgTrafficVolume: 0,
              analyticMarginProfit: 0,
              items: [],
            }

            const subAvgTrafficVolumes = []

            statItem.forEach(item => {
              stat.shows += parseInt(item.shows)
              stat.clicks += parseInt(item.clicks)
              stat.consumption += parseFloat(item.consumption)
              subAvgTrafficVolumes.push(parseFloat(item.avgTrafficVolume))
              stat.analyticRevenue += parseFloat(item.analyticRevenue)
              stat.analyticGoals += parseFloat(item.analyticGoals)
              stat.analyticMarginProfit = stat.analyticRevenue - stat.consumption
              stat.profit += parseFloat(item.profit)
              stat.marginProfit = stat.profit - stat.consumption
              stat.numberOrders += parseInt(item.numberOrders)

              const subObj = {
                // eslint-disable-next-line no-plusplus
                id: `stat${increment++}`,
                criterion: item.criterion,
                shows: item.shows,
                clicks: item.clicks,
                consumption: item.consumption,
                ctr: 0,
                avgCpc: 0,
                profit: item.profit,
                marginProfit: item.marginProfit,
                numberOrders: item.numberOrders,
                analyticRevenue: item.analyticRevenue,
                analyticGoals: item.analyticGoals,
                analyticMarginProfit: item.analyticMarginProfit,
                avgTrafficVolume: item.avgTrafficVolume,
                // calculated crm values
                cr: 0,
                roi: 0,
                cost: 0,
                // calculated analytic values
                analyticRoi: 0,
                analyticCr: 0,
                analyticCost: 0,
              }

              this.calculateDependedFields(subObj)
              stat.items.push(subObj)
            })

            stat.avgTrafficVolume = this.calculateAverage(subAvgTrafficVolumes)

            summarySubAvgTrafficVolumes.push(stat.avgTrafficVolume)

            // set the rule how to calculate analytic and crm
            this.calculateDependedFields(stat)

            // eslint-disable-next-line no-plusplus
            stat.id = `stat${increment++}`
            this.data.push(stat)
            this.saveStatisticData.push(stat)

            this.summaryShows += stat.shows
            this.summaryClicks += stat.clicks
            this.summaryConsumption += stat.consumption
            this.summaryAnalyticRevenue += stat.analyticRevenue
            this.summaryAnalyticGoals += stat.analyticGoals
            this.summaryAnalyticMarginProfit += stat.analyticMarginProfit
            this.summaryProfit += stat.profit
            this.summaryMarginProfit += stat.marginProfit
            this.summaryNumberOrders += stat.numberOrders

            this.summaryCtr = this.summaryShows !== 0
                ? ((parseInt(this.summaryClicks) / parseInt(this.summaryShows)) * 100) : '-'

            this.summaryAvgCpc = this.summaryShows !== 0
                ? ((parseFloat(this.summaryConsumption) / parseInt(this.summaryClicks))) : '-'

            // calculate CRM values
            this.summaryRoi = this.summaryConsumption !== 0
                ? ((parseFloat(this.summaryProfit) - parseFloat(this.summaryConsumption)) / parseFloat(this.summaryConsumption)) * 100 : '-'

            this.summaryCr = this.summaryClicks !== 0
                ? ((parseFloat(this.summaryNumberOrders) / parseInt(this.summaryClicks)) * 100) : '-'

            this.summaryCost = this.summaryNumberOrders !== 0
                ? ((parseFloat(this.summaryConsumption) / parseFloat(this.summaryNumberOrders))) : '-'

            // calculate Analytic values
            this.summaryAnalyticRoi = this.summaryConsumption !== 0
                ? ((parseFloat(this.summaryAnalyticRevenue) - parseFloat(this.summaryConsumption)) / parseFloat(this.summaryConsumption)) * 100 : '-'

            this.summaryAnalyticCr = this.summaryClicks !== 0
                ? ((parseFloat(this.summaryAnalyticGoals) / parseInt(this.summaryClicks)) * 100) : '-'

            this.summaryAnalyticCost = this.summaryAnalyticGoals !== 0
                ? ((parseFloat(this.summaryConsumption) / parseFloat(this.summaryAnalyticGoals))) : '-'
          })

      this.summaryAvgTrafficVolume = this.calculateAverage(summarySubAvgTrafficVolumes)

      let indexCampaign = 0
      this.data.map(c => c.campaign)
          .forEach(campaignText => {
            indexCampaign += 1
            this.campaignOptions.push({
              value: indexCampaign,
              text: campaignText,
            })
          })

      this.isLoading = false
    },
    buildTable(filterTableData, isDefaultRange) {
      this.$store.dispatch('statistic/fetchTableStatistic', filterTableData)
          .then(response => {
            // TO-DO: NEED refactoring
            const dataForParse = response.data
            if (dataForParse === 'NO_STATS') {
              this.emptyStats = dataForParse
            } else {
              if (isDefaultRange) {
                this.$store.commit('statistic/LOAD_TABLE_DATA', dataForParse)
              }
              this.calculateTableData(dataForParse)
            }
          })
          .catch(() => {
            this.notFoundProject = true
          })
    },
    toggle(row) {
      this.$refs.table.toggleDetails(row)
    },
    // eslint-disable-next-line consistent-return
    transitionName() {
      if (this.useTransition) {
        return 'fade'
      }
    },
  },
}

</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
@import '@core/scss/vue/libs/chart-apex.scss';
</style>

<style lang="scss">
@import '@core/scss/vue/libs/tour.scss';
</style>
<style>
.flatpickr-input {
  width: 210px !important;
}

</style>



